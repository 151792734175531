<template><r-data-list
			:headdata="headdata"
			:listdata="filteredList"
			:opt="opts"
			v-if="loaded"
			:searchfilter="searchfilter"
			v-model:qu="q"
		>
			<template v-slot:titleextrabuttons>
					<r-select
						v-model="selectedapprove"
						label="Onay Durumu"
						:data="approveSelect"
						txt="txt"
						val="val"
						width="20%"
					/>
			</template>
			<template v-slot:col7="{datarow}">
				<span class="r-check-ok small" v-if="datarow.isApproved === true"></span>
				<span class="r-check-no small" v-else></span>
			</template>
			<template v-slot:rowextrabtn="{datarow}">
				<r-btn @click="approveCommentModal(datarow)" bgcolor="#15880d"
					>Onayla</r-btn
				>
			</template>
		</r-data-list>
	<r-modal
		:title="
			approveData.questionId + ' nolu soru yorumunu onaylamak istiyor musunuz?'
		"
		v-if="approvemodal"
		v-model:isactive="approvemodal"
	>
		<r-flex v-html="approveData.detail"> </r-flex>
		<template v-slot:otherbuttons>
			<r-btn @click="approveComment(datarow)" bgcolor="#15880d" size="medium">Onayla</r-btn>
		</template>
	</r-modal>
</template>
<script>
import {onBeforeMount, ref, computed} from 'vue'
import {useStore} from 'vuex'
import {trToUpper, mxDate} from '@/mix/global'
export default {
	setup() {
		const store = useStore()
		const listData = ref({})
		const loaded = ref(false)
		const approvemodal = ref(false)
		const selectedapprove = ref(null)
		const approveData = ref({})
		const approveSelect = ref([
			{txt: 'Tümü', val: null},
			{txt: 'Onaylanmış', val: true},
			{txt: 'Onaylanmamış', val: false},
		])
		const q = ref('')
		const searchfilter = v => {
			return (
				trToUpper(v.questionId).includes(trToUpper(q.value)) ||
				trToUpper(v.questionDetail).includes(trToUpper(q.value)) ||
				trToUpper(v.detail).includes(trToUpper(q.value))
			)
		}
		const selectfilter = v => {
			return v.isApproved === selectedapprove.value
		}

		const headdata = computed(() => {
			return [
				{
					title: 'id',
					style: {width: '5%', justifyContent: 'center'},
					key: 'id',
				},
				{
					title: 'Soru No',
					style: {width: '5%', justifyContent: 'center'},
					key: 'questionId',
				},
				{
					title: 'Soru',
					style: {width: '20%'},
					key: 'questionDetail',
					html: true,
				},
				{
					title: 'Yorum',
					style: {width: '30%'},
					key: 'detail',
					html: true,
				},
				{
					title: 'Kayıt Tarihi',
					style: {width: '15%'},
					key: 'createDate',
					cval: v => {
						return mxDate('dd-mmm-yy-ddd-tt', v.createDate, ' ')
					},
				},
				{
					title: 'IP',
					style: {width: '10%', justifyContent: 'center'},
					key: 'ip',
				},
				{
					title: 'Durum',
					style: {width: '5%', justifyContent: 'center'},
					key: 'isApproved',
				},
			]
		})
		const opts = ref({
			title: 'Ders Notları',
			buttons: {
				style: {width: '10%'},
				delete: {
					show:true,
					func: d => {
						deleteFunc(d)
					},
				},
			},
		})
		const filteredList = computed(() => {
			if (selectedapprove.value === null) {
				return listData.value.data
			} else {
				return listData.value.data.filter(selectfilter)
			}
		})
		const getList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'comments/getallcomments',
			})
			return list
		}
		const approveComment = async () => {
			await store.dispatch('postDatas', {
				api: 'comments/approvecomment',
				data: {id: parseInt(approveData.value.id), isapproved: true},
			})
			approvemodal.value = false
		}
		const approveCommentModal = d => {
			approveData.value = d
			approvemodal.value = true
		}
		const deleteFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'comments/deletecomment',
				data: {id: parseInt(d.row.id)},
			})
			listData.value = await getList()
		}
		onBeforeMount(async () => {
			listData.value = await getList()
			loaded.value = true
		})
		return {
			headdata,
			opts,
			loaded,
			approveComment,
			selectedapprove,
			approveSelect,
			filteredList,
			q,
			searchfilter,
			approvemodal,
			approveCommentModal,
			approveData,
		}
	},
}
</script>
